import { useMemo } from "react";
import Affiliate from "../components/Affiliate";
import { getPageContent } from "../utils/contentFetcher";
import { NextPageContext } from "next";

const  AmbassadorProgramTypeLoginPage =({content}: any) =>{
   
    const loginComponent = useMemo(
      () => <Affiliate modalType="login" content={content} />,
      [content]
    );
 
   return loginComponent;
}

export default AmbassadorProgramTypeLoginPage;
 
export async function getServerSideProps(ctx: NextPageContext) {
   const res = await getPageContent("affiliate", ctx);
   return {
     props: {
       content: res.data,
     },
   };
}

  